/** *
 * This functionality has been split from the auth code to prevent cyclic dependencies at places
 */

import utils from '@/app/utils';
import { DateTime } from 'luxon';

const ACCESS_TOKEN_KEY = 'AccessToken';
const ACCESS_TOKEN_EXPIRES_KEY = 'AccessTokenExpires';
const REFRESH_TOKEN_KEY = 'RefreshToken';
const REFRESH_TOKEN_EXPIRES_KEY = 'RefreshTokenExpires';
const EMAIL_KEY = 'LoggedInEmail';

/** *
 * Checks whether existing access token has expired
 * @return {boolean}
 */
function isAccessTokenValid() {
  if (this.fetchAccessToken() == null) {
    return false;
  }

  const expiry = utils.fetchFromLocalStorage(ACCESS_TOKEN_EXPIRES_KEY);
  if (expiry == null) {
    return false;
  }

  return DateTime.now().toMillis() < parseInt(expiry, 10);
}

/** *
 * Checks whether existing refresh token has expired
 * @return {boolean}
 */
function isRefreshTokenValid() {
  if (this.fetchRefreshToken() == null) {
    return false;
  }

  const expiry = utils.fetchFromLocalStorage(REFRESH_TOKEN_EXPIRES_KEY);
  if (expiry == null) {
    return false;
  }

  return DateTime.now().toMillis() < parseInt(expiry, 10);
}

/** *
 * Persists the access token & expiry values to local storage
 * @param email {string} The email address of the user
 * @param accessToken {string} The access token
 * @param accessTokenExpires {number} Epoch timestamp for Access Token validity
 * @param refreshToken {string|null} Optional. The refresh token
 * @param refreshTokenExpires {number|null} Optional. Epoch timestamp for Refresh Token validity
 */
function saveTokens(email, accessToken, accessTokenExpires, refreshToken, refreshTokenExpires) {
  utils.saveToLocalStorage(EMAIL_KEY, email);
  utils.saveToLocalStorage(ACCESS_TOKEN_KEY, accessToken);
  utils.saveToLocalStorage(ACCESS_TOKEN_EXPIRES_KEY, accessTokenExpires.toString());

  if (refreshToken != null && refreshTokenExpires != null) {
    utils.saveToLocalStorage(REFRESH_TOKEN_KEY, refreshToken);
    utils.saveToLocalStorage(REFRESH_TOKEN_EXPIRES_KEY, refreshTokenExpires.toString());
  }
}

/** *
 * Fetches access token from local storage
 * @return {null|*}
 */
function fetchAccessToken() {
  return utils.fetchFromLocalStorage(ACCESS_TOKEN_KEY);
}

function fetchAccessTokenValidityInMs() {
  if (this.isAccessTokenValid()) {
    const accessTokenExpires = parseInt(utils.fetchFromLocalStorage(ACCESS_TOKEN_EXPIRES_KEY), 10);
    const now = new Date().getTime();
    return accessTokenExpires - now;
  }

  return 0;
}

/** *
 * Fetches refresh token from local storage
 * @return {null|*}
 */
function fetchRefreshToken() {
  return utils.fetchFromLocalStorage(REFRESH_TOKEN_KEY);
}

/** *
 * Fetches the logged in email from local storage.
 * @return {*|null}
 */
function fetchLoggedInEmail() {
  return utils.fetchFromLocalStorage(EMAIL_KEY);
}

/** *
 * Clears the access token and expiry from the browser storage
 */
function clearTokens() {
  localStorage.removeItem(EMAIL_KEY);
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(ACCESS_TOKEN_EXPIRES_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_EXPIRES_KEY);
}

export default {
  clearTokens,
  fetchAccessToken,
  fetchAccessTokenValidityInMs,
  fetchLoggedInEmail,
  fetchRefreshToken,
  isAccessTokenValid,
  isRefreshTokenValid,
  saveTokens,
};
