import api from '@/services/api';

async function fetchAllActiveCreditPacks() {
  const url = '/credit_packs';

  try {
    return (await api.get(url)).data;
  } catch (error) {
    console.log(error);
  }

  return null;
}

export default {
  fetchAllActiveCreditPacks,
};
