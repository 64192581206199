<template>
  <div class="container">
    <SiteNavHeader credits="340"></SiteNavHeader>
    <div class="row">
      <div class="col">
        <h3>List of Existing Podcasts</h3>
        <a href="javascript:" @click="loadPodcasts();">Refresh Podcasts</a>
        <i class="fa fa-spin fa-circle-notch fa-fw ms-1" v-show="backgroundDataLoading"></i>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col">
        <table class="table table-striped table-responsive w-100">
          <thead>
            <tr>
              <th>Article Title</th>
              <th style="width: 125px">Date Added</th>
              <th class="text-end" style="width: 90px">Duration</th>
              <th class="text-center" style="width: 50px;">URL</th>
              <th class="text-end" style="width: 125px">Credits Used</th>
            </tr>
          </thead>
          <tbody>
          <tr v-if="!dataLoaded">
            <td colspan="6">
              <i class="fa fa-circle-notch fa-spin"></i>
              Fetching list of articles...
            </td>
          </tr>
          <tr v-if="dataLoaded && (podcastList == null || podcastList.length === 0)">
            <td colspan="6">You have not added any articles to your podcast show. <a href="/app/new">Add one now</a>.</td>
          </tr>
          <tr v-for="pd in podcastList" v-bind:key="pd._id">
            <td>
              {{pd.articleTitle}}
              <i class="fa fa-spin fa-circle-notch ms-2" v-if="pd.taskStatus !== 'COMPLETED'"></i>
            </td>
            <td>{{formatDate(pd.creationTimestamp)}}</td>
            <td class="text-end">
              <span v-if="pd.taskStatus === 'COMPLETED'">
                {{formatDuration(pd.durationInMs)}}
              </span>
              <span v-if="pd.taskStatus !== 'COMPLETED'">
                <i class="fa fa-coffee fa-fw"></i>
              </span>
            </td>
            <td class="text-center">
              <span v-if="pd.url">
                <a :title="pd.url" :href="pd.url" target="_blank"><i class="fa fa-external-link-alt"></i></a>
              </span>
              <span v-if="!pd.url">-</span>
            </td>
            <td class="text-end">
              <span v-if="pd.taskStatus === 'COMPLETED'">
                {{pd.creditsUsed}}
              </span>
              <span v-if="pd.taskStatus !== 'COMPLETED'">
                <i class="fa fa-coffee fa-fw"></i>
              </span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</template>

<script>
import _ from 'lodash';
import SiteNavHeader from '@/components/SiteNavHeader';
import { DateTime, Duration } from 'luxon';
import podcast from '@/app/podcast';

export default {
  name: 'ListArticles',
  components: { SiteNavHeader },
  data() {
    return {
      podcastList: null,
      dataLoaded: false,
      backgroundDataLoading: false,
    };
  },
  methods: {
    async loadPodcasts() {
      if (this.backgroundDataLoading) {
        return;
      }

      const cachedPodcastList = this.$store.getters.getListOfPodcasts;
      const cachedPodcastListUpdatedOn = this.$store.getters.getListOfPodcastsUpdated;

      if (cachedPodcastListUpdatedOn != null) {
        // Load cache and fetch in the background
        this.podcastList = cachedPodcastList;
        this.dataLoaded = true;
      }

      this.backgroundDataLoading = true;
      const podcastList = await this.fetchPodcastList();
      this.backgroundDataLoading = false;
      this.podcastList = _.orderBy(podcastList, ['creationTimestamp'], ['desc']);
      this.dataLoaded = true;
      this.$store.commit('updateListOfPodcasts', podcastList);
    },
    async fetchPodcastList() {
      return podcast.fetchPodcastsForLoggedInUser();
    },
    formatDate(timestamp) {
      if (timestamp == null) {
        return '';
      }

      return DateTime.fromMillis(timestamp).toFormat('dd LLL yyyy');
    },

    formatDuration(milliseconds) {
      if (milliseconds == null) {
        return '';
      }
      return Duration.fromMillis(milliseconds).toFormat('hh:mm:ss');
    },
  },
  async mounted() {
    await this.loadPodcasts();
  },

};
</script>
