// eslint-disable-next-line import/no-cycle
import api from '@/services/api';
import authTokens from '@/app/authTokens';

/** *
 * Requests a magic link from the API server for the specified email address
 * @param email {string} The email address
 * @return {Promise<{success: boolean, error: string}>}
 */
async function sendMagicLink(email) {
  const url = '/auth/magic_link';
  const data = { email };

  const result = {
    success: false,
    error: '',
  };

  try {
    await api.post(url, data);
    result.success = true;
  } catch (error) {
    result.success = false;
    if (error.response && error.response.data) {
      result.error = error.response.data;
    } else {
      result.error = 'The magic link could not be generated. Please try again.';
    }
  }

  return result;
}

/** *
 * Exchanges the magic link token with the server for an access token
 * @param token {string} The magic link token
 * @return {Promise<null|any>}
 */
async function getAccessToken(token) {
  const url = '/auth/token';
  const data = { token };

  try {
    return (await api.post(url, data)).data;
  } catch (error) {
    console.log(error);
  }

  return null;
}

/** *
 * Fetches a new access token from the refresh token
 * @param refreshToken {string} The refresh token
 * @param email {string} The email
 * @return {Promise<null|any>}
 */
async function refreshAccessToken(refreshToken, email) {
  const url = '/auth/refresh';
  const data = { email, refreshToken };

  try {
    return (await api.post(url, data)).data;
  } catch (error) {
    console.log(error);
  }

  return null;
}

/** *
 * Logs out a user
 * @return {Promise<null|any>}
 */
async function logoutUser() {
  const url = '/logout';

  try {
    return (await api.post(url, null)).data;
  } catch (error) {
    console.log(error);
  }

  return null;
}

/** *
 * Sends the email verification token to the server to verify it
 * @param token {string} The email verification token
 * @return {Promise<null|any>}
 */
async function verifyUserEmail(token) {
  const url = '/user/activate';
  const data = { token };

  try {
    return (await api.post(url, data)).data;
  } catch (error) {
    console.log(error);
  }

  return null;
}

/** *
 * Tries to refresh the access token using the stored refresh token.
 * @return {Promise<boolean>}
 */
async function refreshAndSaveAccessToken() {
  // Show waiting
  const refreshToken = authTokens.fetchRefreshToken();
  const loggedInEmail = authTokens.fetchLoggedInEmail();
  const newAccessToken = await this.refreshAccessToken(refreshToken, loggedInEmail);

  if (newAccessToken) {
    // Save the token to local storage
    authTokens.saveTokens(loggedInEmail, newAccessToken.accessToken, newAccessToken.expires, null, null);
    return true;
  }

  // If refresh token is not valid, then clear out all cookies and redirect to login page
  return false;
}

export default {
  sendMagicLink,
  getAccessToken,
  logoutUser,
  verifyUserEmail,
  refreshAccessToken,
  refreshAndSaveAccessToken,
};
