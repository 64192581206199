<template>
  <div class="container">
    <div class="row g-lg-5 py-5">
      <div class="col-12 col-md-6">
        <div>
          <h2 class="fw-bold lh-1 mb-1 d-inline-block">Sign up to lstn.space</h2>
          <h5 class="d-inline-block ms-1 text-muted">Beta</h5>
        </div>

        <div class="py-2 mb-4">
          <router-link to="/"><i class="fa fa-home fa-fw"></i> Back home</router-link>
          |
          <router-link to="/login"><i class="fa fa-sign-in fa-fw"></i> Login Instead</router-link>
        </div>
        <form v-if="!signedUp">
            <div class="mb-3">
              <label for="emailInput" class="form-label required">Email address</label>
              <input type="email" v-model="email" class="form-control" id="emailInput" aria-describedby="emailHelp">
              <div id="emailHelp" class="form-text">All setup instructions and other important information will be sent here.</div>
            </div>
            <div class="mb-3">
              <label for="firstNameInput" class="form-label required">First Name</label>
              <input type="text" v-model="firstName" class="form-control" id="firstNameInput">
            </div>
            <div class="mb-3">
              <label for="lastNameInput" class="form-label required">Last Name</label>
              <input type="text" v-model="lastName" class="form-control" id="lastNameInput">
            </div>
            <div class="mb-3 form-check">
              <input type="checkbox" v-model="tosChecked" class="form-check-input" id="tosCheckbox">
              <label class="form-check-label" for="tosCheckbox">
                By checking this box, I agree to the <a href="/tos" target="_blank">Terms of Service</a> and
              <a href="/privacy" target="_blank">Privacy Policy</a> of the lstn.space service.</label>
            </div>
            <button type="submit"
                    :disabled="!tosChecked || processing"
                    @click="signUpUser()"
                    class="btn btn-primary">
              <span v-if="processing">
                <i class="fa fa-spin fa-circle-notch"></i>
                Signing up...
              </span>
              <span v-if="!processing">
                <i class="fa fa-hand-sparkles fa-fw"></i>&nbsp;
                Sign me up!</span>
            </button>

          <div class="tiny-text text-muted mt-3">
            This site is protected by reCAPTCHA and the Google
            <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and
            <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.
          </div>

          </form>
        <div v-if="signedUp">
          <div class="border bg-light p-4 rounded-3 mt-4">
            <h3 class="fw-bold">
              <i class="fa fa-hand-sparkles fa-fw"></i>
              Awesome sauce!</h3>
            <p>
              A verification email is on its way to your email: {{email}}.
            </p>
            <p>Track down that verification email and click the link! (Peek into your spam or junk folder as well).</p>
            <a :href="checkInboxLink" v-if="checkInboxLink" class="btn btn-success w-100">
              <i class="fa fa-envelope"></i>
              Check your inbox
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <LoginStatusChecker :is-public-page="true"></LoginStatusChecker>
</template>

<script>
import { load } from 'recaptcha-v3';

import utils from '@/app/utils';
import users from '@/app/users';
import LoginStatusChecker from '@/components/LoginStatusChecker';

const SITE_KEY = process.env.VUE_APP_RECAPTCHA_SITE_KEY;

export default {
  name: 'SignUp',
  components: { LoginStatusChecker },
  data() {
    return {
      tosChecked: false,
      email: null,
      firstName: null,
      lastName: null,
      referralCode: null,
      signedUp: false,
      processing: false,
      checkInboxLink: '',
    };
  },
  methods: {
    async signUpUser() {
      // Validation
      if (!utils.validateEmail(this.email)) {
        utils.showToastError('Invalid Email', 'Please enter a valid email address');
        return;
      }

      if (!this.firstName || !this.lastName) {
        utils.showToastError('Names are required', 'Please fill up both - a first and last name to sign up.');
        return;
      }

      if (!this.tosChecked) {
        utils.showToastError('Terms of use not accepted', 'Please accept the Terms of Service to continue.');
        return;
      }

      // Fire the create user command
      this.processing = true;

      const recaptcha = await load(SITE_KEY, {
        autoHideBadge: true,
      });

      const token = await recaptcha.execute('signup');

      const result = await users.createNewUser(this.email, this.firstName, this.lastName, token);
      if (result.error) {
        utils.showToastError('Could not sign up', result.error, true);
      } else {
        this.signedUp = true;
        this.checkInboxLink = utils.fetchEmailProvidersLink(this.email);
      }

      this.processing = false;
    },
  },
};
</script>
