<template>
  <div class="container">
    <SiteNavHeader></SiteNavHeader>
    <div class="row">
      <div class="col">
        <h3>Add a new Article</h3>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12 col-md-7 bg-light p-4 border">
        <!-- Gender -->
        <div class="row">
          <div class="col-12 col-md-6 required">
            Gender of the Narrator
          </div>
          <div class="col-12 col-md-6">
            <label>
              <input type="radio" name="narrator_gender" v-model="gender" value="male">&nbsp;Male
            </label>
            <label class="ms-4">
              <input type="radio" name="narrator_gender" v-model="gender" value="female">&nbsp;Female
            </label>
          </div>
        </div>

        <!-- Accent -->
        <div class="row mt-2">
          <div class="col-12 col-md-6 required">
            Accent of the Narrator
          </div>
          <div class="col-12 col-md-6">
            <label>
              <input type="radio" name="narrator_accent" v-model="accent" value="us">&nbsp;American
            </label>
            <label class="ms-4">
              <input type="radio" name="narrator_accent" v-model="accent" value="uk">&nbsp;British
            </label>
          </div>
        </div>

        <!-- Input Operation -->
        <div class="row mt-3">
          <div class="col-12 border p-2 rounded">
            <div class="fw-bold required">
              How will you add the article?
            </div>
            <div class="mt-2">
              <label>
                <input type="radio" name="input_operation" v-model="inputMethod" value="url">&nbsp;
                I will add in a URL of the article.
              </label>
            </div>
            <div>
              <label>
                <input type="radio" name="input_operation" v-model="inputMethod" value="content">&nbsp;
                I will copy paste the content of the article.
              </label>
            </div>
          </div>
        </div>

        <!-- Add URL of the article -->
        <div class="row mt-3" v-if="inputMethod === 'url'">
          <div class="col border p-2 rounded">
            <div class="fw-bolder required">
              URL of the article
            </div>
            <input type="text" :disabled="processing || processingDone" v-model="articleUrl" class="w-100 my-2" />

            <div class="mt-1">
              <button class="btn btn-primary"
                      @click="fetchArticleContent()"
                      :disabled="articleUrl.length === 0 || articleFetchProcessing">
                <span v-if="!articleFetchProcessing">
                  <i class="fa fa-play fa-fw"></i>
                  Next Step
                </span>
                <span v-if="articleFetchProcessing">
                  <i class="fa fa-spin fa-circle-notch fa-fw"></i>
                  Fetching content of the article ...
                </span>
              </button>
            </div>

          </div>
        </div>

        <!-- Add Content of the article -->
        <div class="row mt-3" v-if="inputMethod === 'content'">
          <div class="col p-0">

            <!-- If article content has been fetched -->
            <div class="alert alert-info d-flex" v-if="articleUrl.length > 0 && articleFetchSuccess">
              <div class="me-2">
                <i class="fa fa-info-circle fa-fw"></i>
              </div>
              <div>
                The article data has been fetched from the URL you shared ({{articleUrl}}) <br />
                Please make sure that it looks alright to you before you send it for processing. <br />
                <br />
                Some websites block external services from fetching content. <br />
                Or it may be behind a pay-wall which we cannot access. <br />
                <br />
                In these cases, you will need to copy-paste the content manually into the text boxes below.
              </div>
            </div>

            <div class="border p-2 rounded">
              <div class="fw-bolder required">
                Title of the Article
              </div>
              <input type="text" :disabled="processing || processingDone" v-model="articleTitle" class="w-100 my-2" />
              <div class="fw-bolder required">
                Article Body
              </div>
              <textarea rows="8"  :disabled="processing || processingDone" v-model="articleContent" class="w-100 mt-2"></textarea>
              <div class="clearfix">
                <div class="float-start credits-calc-box" v-if="articleContent.length > 0">
                  ~ {{approximateCredits}}.
                </div>
                <div class="float-end character-count-box" :class="{ red: (charactersRemaining <= 0) }">
                  {{charactersRemaining.toLocaleString()}} characters remaining.
                </div>
              </div>
            </div>

            <!-- User credits 0 message -->
            <div v-if="userCredits != null && userCredits <= 0">
              <div class="alert alert-danger m-0 mt-2 d-flex">
                <div class="me-2">
                  <i class="fa fa-exclamation-triangle fa-fw"></i>
                </div>
                <div>
                  You do not have any credits in your account. <br />
                  <router-link to="/app/buy">Get some now</router-link> to process articles.
                </div>
              </div>
            </div>

            <!-- Submit Button -->
            <div class="mt-2 text-center text-md-start ps-2" v-if="!processingDone">
              <button type="submit"
                      :disabled="disableSubmitButton"
                      @click="addArticleToPodcast()"
                      class="btn btn-primary" style="margin-left: -10px; width: 200px;">
                <span v-if="processing">
                  <i class="fa fa-fw fa-spin fa-circle-notch"></i> Processing...
                </span>
                <span v-if="!processing">
                  <i class="fa fa-fw fa-paper-plane"></i> Submit
                </span>
              </button>
            </div>

          </div>
        </div>

        <!-- Messages -->
        <div class="row mt-3" v-if="processingDone || processingError">
          <div class="col p-0">
            <div v-if="!processingError" class="alert alert-success d-flex">
              <div class="me-2">
                <i class="fa fa-check"></i>
              </div>
              <div>
                Your article has been scheduled for processing. <br />
                You will receive an email with a status update once the article has been processed.
              </div>
            </div>

            <div v-if="processingError" class="alert alert-danger d-flex mb-0">
              <div class="me-2">
                <i class="fa fa-exclamation-triangle"></i>
              </div>
              <div>
                An error occurred and your article could not be sent for processing. <br />
                Details are below: <br />
                <br />
                <div v-html="convertPlainTextToHtml(processingMessage)"></div>
              </div>
            </div>

            <div class="mt-2" v-if="processingDone">
              <button class="btn btn-primary" @click="addNewArticle()">
                <i class="fa fa-file-plus"></i>&nbsp;
                Add another article</button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-5">
        <AudioSamplesBox></AudioSamplesBox>
      </div>
    </div>
  </div>
  <LoginStatusChecker :is-public-page="false"></LoginStatusChecker>
</template>

<script>
import SiteNavHeader from '@/components/SiteNavHeader';
import utils from '@/app/utils';
// eslint-disable-next-line import/no-cycle
import podcast from '@/app/podcast';
import AudioSamplesBox from '@/components/AudioSamplesBox';
import LoginStatusChecker from '@/components/LoginStatusChecker';

const MAX_CHAR_ALLOWED = process.env.VUE_APP_MAX_CHAR_COUNT_PER_ARTICLE;
const CHAR_PER_MIN = process.env.VUE_APP_CHARACTERS_PER_MIN;

export default {
  name: 'AddNewArticle',
  components: { LoginStatusChecker, AudioSamplesBox, SiteNavHeader },
  computed: {
    charactersRemaining() {
      return MAX_CHAR_ALLOWED - this.articleContent.length;
    },
    approximateCredits() {
      const creditsRequired = Math.max(1, (this.articleContent.length / CHAR_PER_MIN).toFixed(0));
      return creditsRequired === 1 ? '1 credit required' : `${creditsRequired} credits required`;
    },
    userCredits() {
      const user = this.$store.getters.getUser;
      if (user == null) {
        return null;
      }

      return user.credits;
    },
    disableSubmitButton() {
      return this.processing
          || (this.charactersRemaining <= 0)
          || (this.userCredits <= 0)
          || (this.articleTitle.length === 0)
          || (this.articleContent.length === 0);
    },
  },
  data() {
    return {
      inputMethod: 'url',
      articleFetchError: false,
      articleFetchErrorMessage: '',
      articleFetchProcessing: false,
      articleFetchSuccess: false,
      articleTitle: '',
      articleContent: '',
      articleUrl: '',
      accent: 'us',
      gender: 'female',
      processing: false,
      processingDone: false,
      processingError: false,
      processingMessage: null,
    };
  },
  methods: {
    async addArticleToPodcast() {
      if (this.articleTitle == null || this.articleTitle === '') {
        utils.showToastError('Title missing', 'Please enter a title to continue.');
        return;
      }

      if (this.articleContent == null || this.articleContent === '') {
        utils.showToastError('Content missing', 'Please enter content to continue.');
        return;
      }

      this.processing = true;
      const result = await podcast.sendArticleForProcessing(this.gender,
        this.accent, this.articleTitle, this.articleContent, this.articleUrl);

      this.processing = false;
      this.processingDone = true;

      if (result.success) {
        this.processingError = false;
      } else {
        this.processingError = true;
        this.processingMessage = result.message;
        this.processingDone = false;
      }
    },

    addNewArticle() {
      this.resetPage();
    },

    convertPlainTextToHtml(text) {
      return utils.convertTextToHtml(text);
    },

    async fetchArticleContent() {
      if (!this.articleUrl) {
        utils.showToastError('Article URL missing', 'Please enter in the URL of the article to proceed.');
        return;
      }

      if (!this.articleUrl.startsWith('http')) {
        this.articleUrl = `http://${this.articleUrl}`;
      }

      this.articleFetchProcessing = true;
      const response = await podcast.fetchArticleContent(this.articleUrl);
      this.articleFetchProcessing = false;

      if (response.error) {
        const message = `${response.message}<br /><br />Please copy paste the content you would like to add to the podcast.`;
        utils.showToastError('Error fetching article content', message, true);
      } else {
        this.articleTitle = response.article.title;
        this.articleContent = response.article.content;
        this.inputMethod = 'content';
        this.articleFetchSuccess = true;
      }
    },

    resetPage() {
      this.articleTitle = '';
      this.articleContent = '';
      this.articleUrl = '';
      this.processingError = '';
      this.processingDone = false;
      this.articleFetchError = false;
      this.articleFetchErrorMessage = '';
      this.articleFetchSuccess = true;
    },
  },
};
</script>
