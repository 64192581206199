import api from '@/services/api';

async function fetchSaleStatus(saleId) {
  const url = '/purchase/gumroad/verify';
  const data = { saleId };

  try {
    const result = (await api.post(url, data)).data;
    return result == null ? false : result.saleStatus;
  } catch (error) {
    console.log(error);
  }

  return null;
}

export default {
  fetchSaleStatus,
};
