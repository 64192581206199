import { createRouter, createWebHistory } from 'vue-router';
import authTokens from '@/app/authTokens';

import SignUp from '@/views/SignUp';
import Login from '@/views/Login';
import Privacy from '@/views/Privacy';
import TermsOfService from '@/views/TermsOfService';
import AddNewArticle from '@/views/App/AddNewArticle';
import BuyCredits from '@/views/App/BuyCredits';
import ListArticles from '@/views/App/ListArticles';
import Profile from '@/views/App/Profile';
import Magic from '@/views/Magic';
import Logout from '@/views/Logout';
import Activate from '@/views/Activate';
import PaymentDone from '@/views/PaymentDone';
import AutoLogin from '@/views/AutoLogin';
import Home from '../views/Home.vue';

async function requireAuth(to, from, next) {
  if (authTokens.isAccessTokenValid()) {
    next();
    return;
  }

  if (authTokens.isRefreshTokenValid()) {
    next({
      path: '/autologin',
      query: { redirect: to.fullPath },
    });

    return;
  }

  next({
    path: '/login',
    query: { redirect: to.fullPath },
  });
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/signup',
    name: 'Signup',
    component: SignUp,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/autologin',
    name: 'AutoLogin',
    component: AutoLogin,
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
  },
  {
    path: '/tos',
    name: 'TermsOfService',
    component: TermsOfService,
  },
  {
    path: '/app/new',
    name: 'AddNewArticle',
    component: AddNewArticle,
    beforeEnter: requireAuth,
  },
  {
    path: '/app/buy',
    name: 'BuyCredits',
    component: BuyCredits,
    beforeEnter: requireAuth,
  },
  {
    path: '/app/list',
    name: 'ListArticles',
    component: ListArticles,
    beforeEnter: requireAuth,
  },
  {
    path: '/app/profile',
    name: 'Profile',
    component: Profile,
    beforeEnter: requireAuth,
  },
  {
    path: '/magic/:token',
    name: 'Magic',
    component: Magic,
  },
  {
    path: '/app/logout',
    name: 'Logout',
    component: Logout,
  },
  {
    path: '/activate/:token',
    name: 'Activate',
    component: Activate,
  },
  {
    path: '/payment_done',
    name: 'PaymentDone',
    component: PaymentDone,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
