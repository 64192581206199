<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <h6 class="fw-bolder card-title">Check out some Voice Samples</h6>
          <iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1321476022&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/user-287535048" title="lstn.space" target="_blank" style="color: #cccccc; text-decoration: none;">lstn.space</a> · <a href="https://soundcloud.com/user-287535048/sets/listenlater" title="lstn.space Voice Samples" target="_blank" style="color: #cccccc; text-decoration: none;">lstn.space Voice Samples</a></div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: 'AudioSamplesBox',
  mounted() {
  },
};
</script>
