<template>
  <div class="container">
    <div class="row g-lg-5 py-5">
      <div class="col col-md-6">
        <div>
          <h2 class="fw-bold lh-1 mb-1 d-inline-block">Login to lstn.space</h2>
          <h5 class="d-inline-block ms-1 text-muted">Beta</h5>
        </div>

        <div class="py-2 mb-4">
          <router-link to="/"><i class="fa fa-home fa-fw"></i> Back home</router-link>
          |
          <router-link to="/signup"><i class="fa fa-headphones fa-fw"></i> Don't have an account? Create one!</router-link>
        </div>
        <LoginBox></LoginBox>
      </div>
    </div>
  </div>

  <LoginStatusChecker :is-public-page="true"></LoginStatusChecker>
</template>

<script>
import LoginBox from '@/components/LoginBox';
import LoginStatusChecker from '@/components/LoginStatusChecker';

export default {
  name: 'Login',
  components: { LoginStatusChecker, LoginBox },
  data() {
    return {
      email: null,
    };
  },
};
</script>
