<template>
  <div></div>
</template>

<script>
import auth from '@/app/auth';
import authTokens from '@/app/authTokens';
import utils from '@/app/utils';
import users from '@/app/users';

export default {
  props: ['isPublicPage'],
  methods: {
    async refreshAccessTokenAndLogin() {
      // Refresh a new access token and then redirect
      utils.showToastInfo('Auto log in', 'Trying to automatically log you in...', false);

      if (await auth.refreshAndSaveAccessToken()) {
        // Access token was refreshed
        await this.$router.push({ name: 'AddNewArticle' });
        return;
      }

      // If refresh token is not valid, then clear out all cookies and redirect to login page
      await this.$router.push({ name: 'Logout', params: { expired: 1 } });
    },
    async checkLoginStatus() {
      // eslint-disable-next-line no-unreachable
      if (this.isPublicPage) {
        if (authTokens.isAccessTokenValid() && authTokens.isRefreshTokenValid()) {
          await this.$router.push({ name: 'AddNewArticle' });
        } else if (authTokens.isRefreshTokenValid() && !authTokens.isAccessTokenValid()) {
          // Refresh token seems to valid - try getting a new access token and log in.
          await this.refreshAccessTokenAndLogin();
        }
      } else {
        // It is an authenticated page. Check if the user is logged in correctly.

        // Step 1 - Fetch the /me end point. If end point returns value, all good. Exit.
        const userDetails = await users.fetchUser();
        if (userDetails != null) {
          return;
        }

        // Step 2 - If the /me end point throws an error, try and get a new access token and fetch
        // the /me end point again. If it fails, clear the local storage and redirect user to login
        if (authTokens.isRefreshTokenValid()) {
          await this.refreshAccessTokenAndLogin();
        } else {
          // Redirect to sign in page
          await this.$router.push({ name: 'Logout', params: { expired: 1 } });
        }
      }
    },
  },
  async mounted() {
    await this.checkLoginStatus();
  },
};
</script>
