import api from '@/services/api';

async function fetchUser() {
  const url = '/me';
  try {
    return (await api.get(url)).data;
  } catch (error) {
    // Logout user
    await this.$router.push('/app/logout?expired=1');
  }

  return null;
}

async function createNewUser(email, firstName, lastName, captchaToken) {
  const url = '/user';
  const data = {
    email, firstName, lastName, captchaToken,
  };
  const result = {
    error: '',
    user: null,
  };

  try {
    result.user = (await api.put(url, data)).data;
  } catch (error) {
    if (error.response && error.response.data) {
      result.error = error.response.data;
    } else {
      result.error = 'An error occurred and the account could not be created. Please try again.';
    }
  }

  return result;
}

export default {
  fetchUser,
  createNewUser,
};
