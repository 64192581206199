import api from '@/services/api';

async function fetchPodcastsForLoggedInUser() {
  const url = '/pods';

  try {
    return (await api.get(url)).data;
  } catch (error) {
    console.log(error);
  }

  return null;
}

async function sendArticleForProcessing(gender, accent, title, content, articleUrl) {
  const url = '/pods';

  const data = {
    url: articleUrl,
    gender,
    accent,
    title,
    content,
  };

  try {
    return (await api.post(url, data)).data;
  } catch (error) {
    console.log(error);
  }

  return null;
}

async function fetchArticleContent(articleUrl) {
  const url = '/pods/article';
  const data = { url: articleUrl };

  try {
    return (await api.post(url, data)).data;
  } catch (error) {
    console.log(error);
  }

  return null;
}

export default {
  fetchArticleContent,
  fetchPodcastsForLoggedInUser,
  sendArticleForProcessing,
};
