<template>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title fw-bolder">{{name}} ({{priceInUsd}})</h5>
      <table class="table table-striped">
        <tbody>
        <tr>
          <td>Number of Credits</td>
          <td>{{credits}}</td>
        </tr>
        <tr>
          <td>Validity</td>
          <td>{{validity}} days</td>
        </tr>
        </tbody>
      </table>
      <p class="card-text">
        {{summary}}
      </p>
      <a class="btn btn-primary gumroad-button"
         target="_blank"
         :href="purchaseUrl"
         data-gumroad-single-product="true">
        Buy a {{name}} for {{priceInUsd}} <i class="fa fa-external-link-alt fa-fw"></i>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: ['name', 'summary', 'validity', 'credits', 'url', 'email', 'price'],
  computed: {
    priceInUsd() {
      return `$${(this.price / 100).toFixed(2)}`;
    },
    purchaseUrl() {
      return this.email ? `${this.url}?wanted=true&email=${this.email}` : `${this.url}?wanted=true`;
    },
  },
};
</script>
