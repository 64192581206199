<template>
  <footer class="mt-auto py-3">
    <div class="container">
      <div class="row footer">
        <div class="col-12 col-md-6 text-center text-md-start">
          &copy; 2021-22 lstn.space.
          <i class="fa fa-sparkles"></i>
          Made by <a target="_blank" href="https://twitter.com/saurabhj">@saurabhj</a> with
          <i class="fa fa-heart fa-fw"></i> at <a href="https://intellista.com" target="_blank">Intellista</a>.
          All rights reserved.
        </div>
        <div class="col-12 col-md-6 text-center text-md-end">
          <a href="/privacy" target="_blank">Privacy Policy</a>&nbsp;|&nbsp;<a href="/tos" target="_blank">Terms of Service</a>
        </div>
      </div>
    </div>
  </footer>
</template>
