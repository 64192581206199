<template>
  <div class="container">
    <SiteNavHeader></SiteNavHeader>
    <div class="row">
      <div class="col">
        <h3>Buy Credits</h3>
      </div>
    </div>

    <div class="row">
      <div class="col">
        Credits are available as one time purchases and not subscriptions.
      </div>
    </div>

    <div class="row mt-3" v-if="processing">
      <div class="col">
        <i class="fa fa-spin fa-circle-notch fa-fw"></i>
        Fetching the list of available credit packs...
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12 col-md-4 mt-3 mt-md-0" v-for="creditPack in creditPacks" v-bind:key="creditPack._id">
        <CreditPackBox
          :name="creditPack.name"
          :summary="creditPack.summary"
          :validity="creditPack.expiry_days"
          :credits="creditPack.credits"
          :email="userEmail"
          :price="creditPack.price"
          :url="creditPack.product_permalink"
        ></CreditPackBox>
      </div>
    </div>

    <div class="bg-light p-3 mt-4 hero-text-list">
      <h3 class="fw-bold lh-1 mb-3">FAQs</h3>
      <div class="faq-block">
        <div class="question">
          How do credits work?
        </div>
        <div class="answer">
          <p>
            1 credit approximately corresponds to about 1 minute of rich text to speech conversion. <br/>
            After an article is converted, we deduct the credits based on the duration of the
            converted podcast.
          </p>
        </div>
      </div>
      <div class="faq-block">
        <div class="question">
          Do credits roll over?
        </div>
        <div class="answer">
          <p>
            Yes. <br />
            Purchasing a credits pack before your existing credits expire, will add them over to
            the credits of your new pack.
          </p>
          <p>
            For example, if you have 30 credits remaining and purchase another pack with 100 credits,
            your total credits will be 30 + 100 = 130 credits and the validity will be equal to the
            validity of the new pack. i.e. If the new pack has validity of 3 months, your new validity
            will be: [Date of Current pack expiring] + 3 months.
          </p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import SiteNavHeader from '@/components/SiteNavHeader';
import creditPacks from '@/app/creditPacks';
import CreditPackBox from '@/components/CreditPackBox';

export default {
  name: 'BuyCredits',
  components: { CreditPackBox, SiteNavHeader },
  computed: {
    userEmail() {
      const user = this.$store.getters.getUser;
      if (user != null) {
        return user.email;
      }

      return null;
    },
  },
  data() {
    return {
      processing: false,
      creditPacks: [],
    };
  },
  methods: {
    async fetchAllCreditPacks() {
      this.processing = true;
      this.creditPacks = await creditPacks.fetchAllActiveCreditPacks();
      this.processing = false;
    },
  },
  async mounted() {
    await this.fetchAllCreditPacks();
  },
};
</script>
