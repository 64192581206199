<template>
  <router-view/>
  <site-wide-footer></site-wide-footer>
</template>

<style lang="scss">
  @import "@/assets/scss/main.scss";
</style>
<script>
import auth from '@/app/auth';
import authTokens from '@/app/authTokens';
import SiteWideFooter from '@/components/SiteWideFooter';

export default {
  components: { SiteWideFooter },
  data() {
    return {
      refreshTokenTimeoutToken: null,
    };
  },
  async mounted() {
    // App mounted
    if (authTokens.isAccessTokenValid()) {
      // Calculate how much time is left and set a refresh token for T - 1 min
      const timeToCheck = (await authTokens.fetchAccessTokenValidityInMs()) - (60 * 1000);
      console.log(`Need to refresh the AT in ${timeToCheck} ms`);

      this.refreshTokenTimeoutToken = setTimeout(async () => {
        console.log('Trying to refresh access token');
        if (authTokens.isRefreshTokenValid()) {
          await auth.refreshAndSaveAccessToken();
        }
      }, timeToCheck);
    }
    console.log('App mounted. Start a min checker to check login status');
  },
};
</script>
