import { createStore } from 'vuex';

export default createStore({
  state: {
    user: null,
    userLastUpdated: null,
    listOfPodcasts: [],
    listOfPodcastsUpdated: null,
  },
  getters: {
    getUser: (state) => state.user,
    getUserLastUpdated: (state) => state.userLastUpdated,
    getListOfPodcasts: (state) => state.listOfPodcasts,
    getListOfPodcastsUpdated: (state) => state.listOfPodcastsUpdated,
  },
  mutations: {
    updateUser(state, user) {
      this.state.user = user;
      this.state.userLastUpdated = Date.now();
    },
    updateListOfPodcasts(state, listOfPodcasts) {
      this.state.listOfPodcasts = listOfPodcasts;
      this.state.listOfPodcastsUpdated = Date.now();
    },
  },
  actions: {
  },
  modules: {
  },
});
