import toastr from 'toastr';

const FROM_EMAIL = 'jeeves@lstn.space';

/** *
 * Returns the config options required for Axios
 * @param token
 * @param authOnly
 * @return {{headers: {Accept: string}}}
 */
const getAxiosConfig = (token, authOnly) => {
  const config = {
    headers: {
      accept: 'application/json',
    },
  };

  if (authOnly) {
    config.headers.accept = '*/*';
    config.headers['content-type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
  }

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
};

/** *
 * Generates the config options required for Toastr
 * @param isSticky
 * @return {{positionClass: string}}
 */
const getToastConfig = (isSticky) => {
  const config = {
    positionClass: 'toast-top-right',
    progressBar: true,
  };

  if (isSticky) {
    config.timeOut = 0;
    config.closeButton = true;
  }

  return config;
};

/** *
 * Shows an error Toastr
 * @param title
 * @param message
 * @param isSticky
 */
const showToastError = (title, message, isSticky) => {
  toastr.error(message, title, getToastConfig(isSticky));
};

/** *
 * Shows a success Toastr
 * @param title
 * @param message
 * @param isSticky
 */
const showToastSuccess = (title, message, isSticky) => {
  toastr.success(message, title, getToastConfig(isSticky));
};

/** *
 * Shows an info Toastr
 * @param title
 * @param message
 * @param isSticky
 */
const showToastInfo = (title, message, isSticky) => {
  toastr.info(message, title, getToastConfig(isSticky));
};

/** *
 * Clears all open toastr messages
 */
const clearAllToasts = () => {
  toastr.clear();
};

/** *
 * Returns times in a 24h period in the hh:mm ampm format.
 * @return {[]}
 */
const getTimesInDay = () => {
  let hours;
  let minutes;
  let ampm;
  const result = [];

  for (let i = 0; i <= 1440; i += 15) {
    hours = Math.floor(i / 60);
    minutes = i % 60;

    if (minutes < 10) {
      minutes = `0${minutes}`; // adding leading zero
    }

    ampm = hours % 24 < 12 ? 'AM' : 'PM';
    hours %= 12;

    if (hours === 0) {
      hours = 12;
    }

    const time = `${hours}:${minutes} ${ampm}`;

    result.push(time);
  }

  return result;
};

/** *
 * Checks email for basic email pattern
 * @param email
 * @return {boolean}
 */
function validateEmail(email) {
  if (email == null || email === '') {
    return false;
  }

  const regexp = '[^\\s@]+@[^\\s@]+\\.[^\\s@]+';
  const result = email.match(regexp);

  return result != null && result.length === 1 && result[0] === email;
}

/** *
 * Calculates the URL which would open the email inbox for the specific domain with proper filters
 * @param email
 * @return {string|null}
 */
function fetchEmailProvidersLink(email) {
  if (email == null || email.trim() === '') {
    return null;
  }

  const emailParts = email.split('@');
  if (emailParts == null || emailParts.length !== 2) {
    return null;
  }

  const domain = emailParts[1].toLowerCase();

  // Check if GMail based address
  if (domain === 'gmail.com' || domain === 'googlemail.com') {
    return [
      'https://mail.google.com/mail/u/0/#search/',
      encodeURIComponent(`from:${FROM_EMAIL}`),
      '+',
      encodeURIComponent('is:unread'),
    ].join('');
  }

  // Check if yahoo based address
  if (domain === 'ymail.com' || domain.indexOf('yahoo.') > -1) {
    return [
      'https://mail.yahoo.com/d/search/keyword=',
      encodeURIComponent(`from:${FROM_EMAIL} is:unread`),
    ].join('');
  }

  return null;
}

/** *
 * Saves data to Local Storage
 * @param key {string} The key
 * @param value {string} The Value
 */
function saveToLocalStorage(key, value) {
  localStorage[key] = value;
}

/** *
 * Fetches data from local storage
 * @param key {string} The Key
 * @return {null|*}
 */
function fetchFromLocalStorage(key) {
  if (localStorage[key]) {
    return localStorage[key];
  }

  return null;
}

/** *
 * Checks if the user's credits have expired
 * @param user {Object} The user object
 * @return {boolean}
 */
function hasUserCreditsExpired(user) {
  if (user != null) {
    const { creditsExpiry } = user;
    if (creditsExpiry == null || creditsExpiry === 0) {
      return false;
    }

    const now = Date.now();
    return creditsExpiry <= now;
  }

  return false;
}

/** *
 * Calculates the length of time credits are worth
 * @param credits {number} The number of credits
 * @return {string} The time duration in friendly format
 */
function calculateTimeForCredits(credits) {
  if (credits < 60) {
    if (credits === 1) {
      return '1 minute';
    }

    return `${credits} minutes`;
  }

  const hour = Math.floor(credits / 60);
  const minute = credits % 60;

  let result = '';
  if (hour === 1) {
    result = '1 hour';
  } else {
    result = `${hour} hours`;
  }

  if (minute > 0) {
    if (minute === 1) {
      result += ', 1 minute';
    } else {
      result += `, ${minute} minutes`;
    }
  }

  return result;
}

function convertTextToHtml(plainText) {
  return plainText == null ? null : plainText.replace('\n', '<br />');
}

export default {
  getAxiosConfig,
  getTimesInDay,
  showToastSuccess,
  showToastError,
  showToastInfo,
  clearAllToasts,
  validateEmail,
  fetchEmailProvidersLink,
  saveToLocalStorage,
  fetchFromLocalStorage,
  hasUserCreditsExpired,
  calculateTimeForCredits,
  convertTextToHtml,
};
