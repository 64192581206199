<template>
  <div class="p-4 border rounded-3 bg-light">
    <div class="mb-2 text-start">
      <strong>Login</strong>
    </div>

    <div class="form-floating mb-3">
      <input type="email" class="form-control" id="floatingInput"
             v-model="emailAddress"
             v-on:keyup.enter="requestMagicUrl()"
             required
             placeholder="rhendriks@piedpiper.com" autocomplete="off">
      <div class="form-text">A magic link to login will be sent to this email.</div>
      <label for="floatingInput">Email address</label>
    </div>
    <button class="w-100 btn btn-lg btn-primary"
            :disabled="sendingEmail"
            @click="requestMagicUrl()" type="button">
      <span v-if="!sendingEmail">
        <i class="fa fa-magic fa-fw"></i> Send me a Magic Link
      </span>
      <span v-if="sendingEmail">
        <i class="fa fa-circle-notch fa-spin fa-fw"></i> Sending...
      </span>
    </button>

    <div class="mt-2 alert alert-success mb-0" v-if="emailSent">
      <div>
        If your email address is registered with us, you should have now received an email with login
        instructions. Check your email.
      </div>
      <a :href="checkInboxLink" v-if="checkInboxLink" class="btn btn-success w-100 mt-2">
        <i class="fa fa-envelope"></i>
        Check your inbox
      </a>
    </div>

  </div>
</template>

<script>
import auth from '@/app/auth';
import utils from '@/app/utils';

export default {
  name: 'LoginBox',
  data() {
    return {
      emailAddress: '',
      checkInboxLink: '',
      emailSent: false,
      sendingEmail: false,
    };
  },
  methods: {
    async requestMagicUrl() {
      utils.clearAllToasts();

      if (!this.emailAddress || !utils.validateEmail(this.emailAddress)) {
        utils.showToastError('Invalid email', 'Please use a valid email address.', false);
        return;
      }

      this.emailSent = false;
      this.sendingEmail = true;

      const result = await auth.sendMagicLink(this.emailAddress);

      if (result.success) {
        this.emailSent = true;
        this.checkInboxLink = utils.fetchEmailProvidersLink(this.emailAddress);
        this.emailAddress = '';
        this.sendingEmail = false;
      } else {
        this.emailSent = false;
        this.sendingEmail = false;

        const errorMessage = result.error
          ? result.error
          : 'The login email could not be sent. Please try again. If you are retrying, please wait 10 seconds between tries.';

        utils.showToastError('Magic link could not be sent', errorMessage, true);
      }
    },
  },
};
</script>
