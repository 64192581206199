<template>
  <div class="container">
    <div class="row g-lg-5 pt-5">
      <div class="col">
        <h2 class="fw-bold lh-1 mb-1">Logout from lstn.space</h2>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <i class="fa fa-spin fa-circle-notch"></i>&nbsp;
        {{logoutMessage}} Just a moment...
      </div>
    </div>
  </div>
</template>

<script>
import auth from '@/app/auth';
import authTokens from '@/app/authTokens';

export default {
  name: 'Logout',
  components: { },
  data() {
    return {
      logoutMessage: 'Logging you out.',
    };
  },
  methods: {
    async logoutUser() {
      // Step 1 - If access token exists and is valid, logout from the server
      const accessToken = authTokens.fetchAccessToken();
      if (accessToken && authTokens.isAccessTokenValid()) {
        await auth.logoutUser();
      }

      // Step 2 - Clear all the tokens
      authTokens.clearTokens();

      // Step 3 - Redirect to Home
      await this.$router.push({ name: 'Home' });
    },
  },
  async mounted() {
    if (this.$route.query.expired === '1') {
      this.logoutMessage = 'Your session expired. Redirecting you to the login page.';
    }
    await this.logoutUser();
  },
};
</script>
